hr {
    visibility: hidden;
}

.dmv-wrapper {
    box-sizing: border-box !important;
    width: auto;
}

.logo-img {
    display: flex;
    width: 391px;
    height: 81px;
}
.invitebutton{
    background-color:var(--main) !important;
    width:87%;
    margin-left:7% !important;
    margin:auto;

}

.main-content {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 8%;
}

#txt-1,
#txt-2 {
    font-family: 'Roboto', sans-serif;
    font-size: 50px;
    margin: 0.5%;
}

#txt-2 {
    color: var(--main);
}

#txt-3 {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    justify-content: center;
    text-align: center;
    width: 800px;
}

#btn-1 {
    background-color: var(--main);
    width: 400px;
}

#modal-1 {
    width: 40%;
    height: 85%;
    margin: auto;
    background-color: 'background.paper';
    z-index: 10;

}

#bx-1,
#bx-2 {
    border-radius: 2% !important;
}

#modal-modal-title {
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 5%;
    padding-left: 0%;
    width: 380px;
    justify-content: left;
    font-family: 'Roboto', sans-serif;
}

#modal-modal-description {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: auto;
    width: 380px;
    /* padding-top:4%; */
}

#cross {
    /* margin-right:-600%; */
    /* margin-top:1%; */
    position: absolute;
    top: 10px;
    /* Adjust the distance from the top as needed */
    right: 10px;
    /* Adjust the distance from the right as needed */
    cursor: pointer;
}

#but-3 {
    background-color: var(--main);
    width: 380px;
    margin-left: 28px;
}

#confirmation {
    width: 150px;
    margin-left: 30%;
    margin-top: 5%;
}

#child-modal-title {
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 10%;
    width: 380px;
    justify-content: center;
    font-family: 'Roboto', sans-serif;
}

#child-modal-description {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: auto;
    width: 380px;
    /* padding-top:4%; */
}

.steps {
    width: 75%;
    margin: 5rem auto;
    font-family: 'Roboto', sans-serif;
}

.steps span {
    color: #D4812F;
    margin-right: 2rem;
}

.steps>h3 {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0;
    padding: 0;
}

.step {
    border-radius: 20px;
    border: 0.3px solid #000;
    padding: 1rem 2rem;
    width: 90%;
    margin: 2rem auto;
}

.step img {
    margin: 0 auto;
    display: block;
    object-fit: contain;
    width: 15vw;
    height: auto;
}