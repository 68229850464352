.dashboardWrapper {
    font-family: Outfit;
    padding-bottom: 5rem;
}

.dashboardWrapper main {
    margin-left: 5rem;
    display: flex;
}

.dashboardWrapper>header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #4F1212;
    text-align: center;
    font-family: 'Outfit',
        sans-serif;
    font-size: 2rem;
    font-style: normal;
    font-weight: 900;
    line-height: 30px;
    padding-left: 2rem;
    background: #F8F8F8;
    box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.25);
    height: 15vh;
    margin-bottom: 2rem;
    position: fixed;
    width: 100%;
}

.sideBarWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 5rem;
    position: absolute;
    position: fixed;
    top: 20vh;
}

.sideBarWrapper img {
    margin: 0.5rem 0;
    cursor: pointer;
}

/* ==== Wallet ==== */

.walletWrapper>h1 {
    text-align: start;
    padding-left: 5rem;
    display: flex;
    width: 532px;
    height: 40px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    font-weight: 400;
    font-family: Outfit;
    padding-top: 10rem;
}

.amounts {
    height: 100%;
    width: 20vw;
}

.amount {
    border-radius: 15px;
    background: #FFF;
    box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    height: 100%;
    padding: 1rem;
    color: #000;
    font-family: Outfit;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
    text-align: left;
    position: relative;
    padding-right: 5rem;
}

.amount span {
    color: rgba(0, 0, 0, 0.40);
    font-family: Mukta;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.25px;
}

.amount img {
    position: absolute;
    right: 1rem;
}

.btns {
    height: 100%;
    padding: 1rem;
    display: flex;
    justify-content: space-around;
    align-items: space-between;
}

.btns button {
    margin: 0 1rem;
    cursor: pointer;
}

.btns button:nth-child(1) {
    all: unset;
    border-radius: 10px;
    background: #FF6464;
    box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.25);
    color: #000;
    font-family: Outfit;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    padding: 0.5rem 1rem;
}

.btns button:last-child {
    all: unset;
    color: #000;
    font-family: Outfit;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    border-radius: 10px;
    background: #95FF64;
    box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.25);
    padding: 0.5rem 1rem;
}

.rewards {
    border-radius: 15px;
    background: #FFF;
    box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.25);
    width: 60vw;
    margin-left: 3rem;
    position: relative;
}

.rewards h1 {
    color: #000;
    font-family: Outfit;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    text-align: start;
    padding-left: 2rem;
}

.trophy {
    position: absolute;
    right: 1rem;
    top: 1rem;
}

/* ===== delegates ====== */

.delegatesWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 2rem;
    width: 100%;
    box-sizing: border-box;
    color: #000;
    font-family: Outfit;
    font-family: 'Outfit',
        sans-serif;
    padding-top: 20vh;
}

.admins,
.staff {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: 'Outfit',
        sans-serif;
}


.admins h1,
.staff h1 {
    margin: 0;
    font-family: 'Outfit',
        sans-serif;
    color: #000;
    font-style: normal;
    font-weight: 400;
    padding-left: 2rem;
    text-align: start;
    margin-bottom: 1rem;
}

.staff h1 {
    margin-top: 2rem;
}

.user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80vw;
    background: #D9D9D9;
    padding: 0 2rem;
    border: 1px solid black;
    border-radius: 8px;
}

.user p {
    color: #000;
    font-family: Mukta;
    font-size: 1.05rem;
    font-style: normal;
    font-weight: 400;
}

.userButtons button {
    all: unset;
    color: #D4812F;
    text-align: center;
    font-family: Mukta;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    border-radius: 20px;
    padding: 0.5rem 1.5rem;
    cursor: pointer;
    border: 1px solid #D4812F;
    margin: 0 1rem;
}

.userButtons button:nth-child(1) {
    background: #D4812F;
    color: #fff;
    border: none;
}