:root {
  --main: #CD7F32;
}

* {
  font-family: 'Roboto', sans-serif;

}

.App {
  text-align: center;
  background-color: #EEEEEE;
}