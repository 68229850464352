.Info3 {
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 100vh;
    background-color: azure;
}

.leftco {
    flex: 2 1 0;
    margin-top: 5%;
    margin-right: 5%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 5%;
}

.rightco {
    flex: 1 1 0;
    margin-right: 5%;
    margin-top: 5%;

}

.infohead {
    color: black;
    font-family: 'Roboto', sans-serif;
    font-size: 35px;
}

.infoinput {
    height: 2.5rem;
    width: 399px;
    /* margin-left: -208px; */
    border-radius: 2px;
}

/* .info-3-di{
    display:flex;
    flex-direction:row;
}  */
.black-icon {
    height: 50px;
    width: 50px;
    background-color: black;
    color: white;
}