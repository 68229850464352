.Info1{
    background-color: white;
    width:100vw;
    height:100vh;
    display:flex;
    flex-direction: row;
    overflow: hidden;
}
/*Flex Grow-Flex Shrink-Flex Basis*/
.leftco1{
    flex: 2 1 0;
    margin-left: 5%;
    margin-top: 5%;
}
.rightco1{
    flex: 1 1 0;
    margin-top: 5%;
    margin-right: 5%;
}
.infohead{
    font-family: 'Roboto', sans-serif;
    font-size: 35px;
    margin-top: 5%;
    margin-left: 5%;
    color:var(--main);
}
.infopara{
    font-size:23px;
    margin-top: 5%;
    margin-left: 5%;
}