.wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    /*1fr 1fr 1fr*/
    grid-template-rows: repeat(3, 1fr);
}

.leftpart {
    grid-column: 1/2;
    grid-row: 1/3;
}

.rightpart {
    grid-column: 3/4;
    grid-row: 2/4;
}

.logo-img {
    display: flex;
    width: 350px;
    height: 80px;
    margin-top: 2%;
    margin-left: 2%;
    flex-direction: column;
    justify-content: center;
}

.centre-txt {
    width: 679px;
    font-family: 'Roboto', sans-serif;
    font-size: 35px;
    margin-left: 67px;
    margin-top: 100px;
    line-height: 30px;
    /* 75% */
    text-align: left;
}

#sentence-1 {
    color: black;
}

#sentence-2 {
    color: var(--main);
}

.centre-blocks {
    display: flex;
    flex-direction: row;
    width: 746px;
}

.block-1 {
    width: 300px;
    height: 180px;
    background-color: white;
    margin-left: 67px;
}

.block-2 {
    width: 300px;
    height: 180px;
    background-color: white;
    margin-left: 79px;
}

h3 {
    font-family: 'Roboto', sans-serif;
    text-align: left;
}

p {
    font-family: 'Roboto', sans-serif;
    text-align: left;
}

#block-h3 {
    position: absolute;
    font-size: 20px;
    margin-top: 20px;
    margin-left: 20px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
}

#block-p {
    position: absolute;
    font-size: 15px;
    margin-top: 60px;
    margin-left: 20px;
}

#block-but-1,
#block-but-2 {
    width: 110px;
    top: 70%;
    margin-left: -150px;
}

#block-but-1 {
    background-color: var(--main);
}

#block-but-2 {
    color: var(--main);
    border-color: var(--main);
}

.Button:disabled {
    color: var(--main);
}

.mydmv-govtid {
    display: flex;
    flex-direction: row;
    margin-bottom: 5%;
    margin-top: 10%;
    width: 435px;
}

#but1,
#but2,
#but3,
#but-4,
#but5 {
    border-radius: 5px;
}

#but1,
#but2 {
    margin: auto;
    width: 179px;
}

#but1 {
    background-color: #FF3232;
}

#but2 {
    background-color: #39ACE7;
}

#but3 {
    background-color: white;
    color: black;
    border-color: black;

    margin-left: 5%;
    width: 395px;
}

.txt {
    margin: 5px;
    width: 50%;
    display: flex;
    flex-direction: column;
    margin: auto;
    justify-content: center;
    align-items: center;

}

#hehe {
    color: white;
}

.txtfields {
    display: flex;
    flex-direction: column;
    width: 395px;
    margin: auto;
}

#bx-3 {
    padding-top: 5%;
}

#txtfields2 {
    display: flex;
    flex-direction: column;
    width: 395px;
    margin: auto;
}

#txtfield1,
#txtfield2 {
    height: 10px;
    margin-bottom: 5px;
}

.forgotpasscreateacc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    width: 395px;
    height: auto;
    margin: auto;
}

.createacc {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    margin-right: 5% !important;
}

#but-4 {
    color: var(--main);
    height: 30px;
    margin: 0%;
    padding: 0%;
}

.forgor,
.createacc {
    margin: auto;

}

#but5 {
    width: 395px;
    background-color: var(--main);
    color: white;
    margin-left: 4.9%;
}

#cros {
    top: 8px;
    right: 8px;
    position: absolute;
}

.checkboxxx {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10%;
}

.centrerect {
    display: flex;
    flex-direction: column;
    margin: auto;
}
.installappWrapper{
    display:flex;
    flex-direction: column;
}
.Nameofproject{
    display: flex;
    flex-direction: row;
}
.Nameofproject h2{
    font-weight: 500;
}
.buttonssss{
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
}
.butttons{
    color:var(--main) !important;
}
#tblox{
    width:40%;
    height:auto;
}
/* Adjustments for smaller screens */
@media (max-width: 500px) {
    .wrapper {
        display: grid;
        grid-template-columns: 4fr;
        /*1fr 1fr 1fr*/
        grid-template-rows: 1fr 1fr 2fr;
    }

    .leftpart {
        grid-column: 1/5;
        grid-row: 1/4;
    }

    .rightpart {
        display: none;
    }

    .logo-img {
        width: 30% !important;
        margin-left: 10% !important;
        margin: 0%;
    }

    .centre-blocks {
        grid-row: 3/4;
        display: grid;
        grid-template-columns: 2fr 2fr;
        grid-template-rows: 1fr 1fr;
    }

    .block-1 {
        grid-row: 1;
        grid-column: 1/2;
        width: 50%;
        height: 75%;
        /* height: 150px; */
        margin: auto;
        background-color: white;
        margin-left: 5%;
        margin-bottom: 5%;
        border-radius: 5%;
    }

    .block-2 {
        grid-row: 2;
        grid-column: 1/2;
        width: 50%;
        height: 150px;
        background-color: white;
        margin-left: 5%;
        border-radius: 5%;
    }

    #block-h3 {
        position: absolute;
        font-size: 15px;
        margin-top: 3%;
        margin-left: 3%;
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
    }

    #block-p {
        position: absolute;
        font-size: 10px;
        margin-top: 10%;
        margin-left: 3%;
    }

    #sentence-2 {
        margin-left: 25%;
    }

    .centre-txt {
        margin-left: 5% !important;
        margin: 0%;
        padding: 0%;
        width: 300px;
        font-size: 90%;
        margin: auto !important;
    }

    #block-but-1,
    #block-but-2 {
        font-size: 10px;
        width: 90px;
        top: 70%;
        margin-left: 3%;
    }

}