.delegate-onboarding-wrapper {
    font-family: 'Outfit',
        sans-serif;
    font-family: Outfit;
    /* padding-bottom: 7rem; */
}

.delegate-onboarding-wrapper h1:nth-child(1) {
    all: unset;
    font-size: 4rem;
    font-family: 'Outfit',
        sans-serif;
    text-transform: uppercase;
    color: #4F1212;
    font-weight: 900;
    display: block;
    text-align: start;
    padding-left: 2rem;
}

.delegate-onboarding-wrapper>h3 {
    text-align: center;
    color: #000;
    text-align: center;
    font-family: Outfit;
    font-size: 2rem;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;

}

.delegate-onboarding-wrapper h4 {
    color: #000;
    text-align: start;
    font-family: Outfit;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    padding-left: 2rem;
    font-size: 1.5rem;
}

.delegate-input label {
    color: rgba(0, 0, 0, 0.50);
    font-family: Outfit;
    font-size: 20px;
    font-weight: 300;
}

.delegate-onboarding-wrapper .delegate-label {
    color: #000;
    font-family: Outfit;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    margin-bottom: 0.5rem;
    text-align: start !important;
    width: 50%;
}

.delegate-onboarding-wrapper form {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.form-input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}



.delegate-input {
    /* font-family: 'Outfit',
        sans-serif; */
    font-family: Outfit !important;
}

.delegate-button {
    all: unset;
    color: #FFF;
    text-align: center;
    font-family: Outfit;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    background: var(--main);
    cursor: pointer;
    border-radius: 20px;
    padding: 1rem 2rem;
    margin: 4rem 5rem;
}