.Info2{
        background-color: #FBCACA;
        width: 100vw;
        height: 100vh;
        display:flex;
        flex-direction: row;
        /*align vertically in center*/
        /* justify-content: space-between; */
}
.leftco{
    flex: 1 1 0;
    margin-top: 5%;
    margin-right: 5%;
}
.rightco{
    flex: 2 1 0;
    margin-right:5%;
    margin-top: 5%;
}
.infohead{
    font-family: 'Roboto', sans-serif;
    font-size: 35px;
    /* margin-top: 5%;
    margin-left: 5%; */
    color:var(--main);
}
.infopara{
    font-size:23px;
    /* margin-top: 5%;
    margin-left: 5%; */
}
.infopara{
    text-align:right;
    /* margin-right:5%; */
}
.infohead{
    text-align:right;
    /* margin-right:5%; */
}