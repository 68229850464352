*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  /* height: 100vh; */
  background-color: #EEEEEE;
}

.wrapper {
  /* background: linear-gradient(60deg, #420285, #08BDBD); */
  width: 100%;
  height: 100%;
}

.carousel {
  position: relative;
  width: 100%;
  /* max-width: 500px; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* margin-top:-370px; */
  /* margin-left:100px; */
}

.carousel__item {
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  padding: 0 12px;
  opacity: 0;
  /* filter: drop-shadow(0 2px 2px #555); */
  will-change: transform, opacity;
  -webkit-animation: carousel-animate-vertical 27s linear infinite;
  animation: carousel-animate-vertical 27s linear infinite;
  background-color: red !important;
}

.carousel__item:nth-child(1) {
  -webkit-animation-delay: calc(3s * -1);
  animation-delay: calc(3s * -1);
}

.carousel__item:nth-child(2) {
  -webkit-animation-delay: calc(3s * 0);
  animation-delay: calc(3s * 0);
}

.carousel__item:nth-child(3) {
  -webkit-animation-delay: calc(3s * 1);
  animation-delay: calc(3s * 1);
}

.carousel__item:nth-child(4) {
  -webkit-animation-delay: calc(3s * 2);
  animation-delay: calc(3s * 2);
}

.carousel__item:nth-child(5) {
  -webkit-animation-delay: calc(3s * 3);
  animation-delay: calc(3s * 3);
}

.carousel__item:nth-child(6) {
  -webkit-animation-delay: calc(3s * 4);
  animation-delay: calc(3s * 4);
}

.carousel__item:nth-child(7) {
  -webkit-animation-delay: calc(3s * 5);
  animation-delay: calc(3s * 5);
}

.carousel__item:nth-child(8) {
  -webkit-animation-delay: calc(3s * 6);
  animation-delay: calc(3s * 6);
}

.carousel__item:last-child {
  -webkit-animation-delay: calc(-3s * 2);
  animation-delay: calc(-3s * 2);
}

.carousel__item-head {
  position: relative;
  display: flex;
  align-items: center;
}

.carousel__item-head>img {
  position: absolute;
  width: 3rem;
  border-radius: 50%;
  height: auto;
  z-index: 2;
  vertical-align: center;
}

.carousel__item-body {
  /* width: 100%; */
  background-color: #fff;
  border-radius: 8px;
  /* padding: 20% 500% 10% 13%; */
  padding-right: 1rem;
  box-sizing: border-box;
  transform: translateX(-2vw);
}

.title {
  /* text-transform: uppercase; */
  /* width: 280px; */
  font-size: 20px;
  /* width: 80%; */
  margin-top: 10px;
  margin-left: 90px;
}

.para {
  margin-left: 90px;
  /* width: 280px; */
  font-size: 15px;
  display: block;
  width: 20vw;
}

@-webkit-keyframes carousel-animate-vertical {
  0% {
    transform: translateY(100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }

  3%,
  11.1111111111% {
    transform: translateY(100%) scale(0.7);
    opacity: 0.4;
    visibility: visible;
  }

  14.1111111111%,
  22.2222222222% {
    transform: translateY(0) scale(1);
    opacity: 1;
    visibility: visible;
  }

  25.2222222222%,
  33.3333333333% {
    transform: translateY(-100%) scale(0.7);
    opacity: 0.4;
    visibility: visible;
  }

  36.3333333333% {
    transform: translateY(-100%) scale(0.5);
    opacity: 0;
    visibility: visible;
  }

  100% {
    transform: translateY(-100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes carousel-animate-vertical {
  0% {
    transform: translateY(100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }

  3%,
  11.1111111111% {
    transform: translateY(100%) scale(0.7);
    opacity: 0.4;
    visibility: visible;
  }

  14.1111111111%,
  22.2222222222% {
    transform: translateY(0) scale(1);
    opacity: 1;
    visibility: visible;
  }

  25.2222222222%,
  33.3333333333% {
    transform: translateY(-100%) scale(0.7);
    opacity: 0.4;
    visibility: visible;
  }

  36.3333333333% {
    transform: translateY(-100%) scale(0.5);
    opacity: 0;
    visibility: visible;
  }

  100% {
    transform: translateY(-100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }
}